/* Add Tailwindcss-ish margin and padding helpers */

$sizes: 
  0 0,
  1 0.25,
  2 0.5,
  3 0.75,
  4 1,
  5 1.25,
  6 1.5,
  8 2,
  10 2.5,
  12 3,
  16 4,
  20 5,
  24 6,
  32 8,
  40 10,
  48 12,
  56 14,
  64 16;
$positions: ('top','left','bottom','right');

@each $index, $size in $sizes {
  @each $position in $positions {
    .m#{str-slice($position, 0, 1)}-#{$index} {
      margin-#{$position}: $size + rem !important;
    }
    .p#{str-slice($position, 0, 1)}-#{$index} {
      padding-#{$position}: $size + rem !important;
    }
    .-m#{str-slice($position, 0, 1)}-#{$index} {
      margin-#{$position}: -$size + rem !important;
    }
  }
  .mx-#{$index} {
    margin-left: $size + rem !important;
    margin-right: $size + rem !important;
  }
  .px-#{$index} {
    padding-left: $size + rem !important;
    padding-right: $size + rem !important;
  }
  .-mx-#{$index} {
    margin-left: -$size + rem !important;
    margin-right: -$size + rem !important;
  }
  .my-#{$index} {
    margin-top: $size + rem !important;
    margin-bottom: $size + rem !important;
  }
  .py-#{$index} {
    padding-top: $size + rem !important;
    padding-bottom: $size + rem !important;
  }
  .-my-#{$index} {
    margin-top: -$size + rem !important;
    margin-bottom: -$size + rem !important;
  }
}

.m-auto {
  margin: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
