component-preview-area {
  display: block;
  border: 2px dashed #ccc;
  padding: 30px 50px;

  .content {
    h4 {
      font-weight: 500;
      opacity: 0.6;
    }

    h4:not(:first-of-type) {
      margin-top: 3rem;
    }
  }
}
