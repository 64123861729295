article-author,
article-content, /* .content */
img-container,
layout-columns, /* .columns */
layout-notice,
layout-spacer,
main-content /* .content */ {
  display: block;
}

ui-label {
  display: inline;
}

/*
# Class names to use for these elements:

button-group = .buttons
layout-box = .box
layout-column = .column
layout-sidebar = .column
nav-inner = .container
nav-menu = .navbar-menu
nav-section = .navbar-brand, .navbar-start, .navbar-end
ui-icon = .icon
*/
