component-preview-metadata {
  display: block;
  dt {
    font-weight: 500;
    opacity: 0.75;
    &:not(:first-of-type) {
      margin-top: 0.75rem;
    }
  }
  margin-bottom: 2rem;
}
