.note {
  background: lighten($green, 25%);
  padding: 40px;
  padding-left: calc(2rem - 5px);
  padding-right: 2rem;
  border-left: solid 5px $green;
  font-size: 0.9em;
  font-weight: 500;
  margin-left: -2rem;
  margin-right: -2rem;
  @include mobile {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  margin-bottom: 1.5rem;
  &.warning {
    border-left-color: $red;
    background: lighten($red, 35%);
  }
}

table + .note {
  margin-top: 1.5rem;
}
