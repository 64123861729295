// Set up Swup
// override theme speeds
.swup-transition-main {
  transition: opacity 0.2s, transform 0.2s;
}

html.is-animating {
  .swup-transition-main {
    transform: translate3d(0, -10px, 0);
  }

  &.is-leaving {
    .swup-transition-main {
      transform: translate3d(0, 10px, 0);
    }

    &.swup-theme-reverse {
      .swup-transition-main {
        transform: translate3d(0, 10px, 0);
      }

      &.is-leaving {
        .swup-transition-main {
          transform: translate3d(0, -10px, 0);
        }
      }
    }
  }
}

.content {
  body.post & h3 {
    text-align: center;
    &:not(:first-child) {
      margin-top: 3.5rem;
    }
    margin-bottom: 2.5rem;
    color: $red;
  }

  .highlight {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h2:not(:first-child) {
    margin-top: 1.4285em;
  }
}

body.docs .content {
  .heading-anchor {
    color: $info;
    font-size: 0.9em;
    margin-left: 0.5em;
    opacity: 0;
    transition: opacity 0.3s;
  }
  h2:hover .heading-anchor,
  h3:hover .heading-anchor {
    opacity: 1;
  }
}

.box.px-8 {
  @include mobile {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.box ul,
.box ol {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  width: 85%;

  li + li {
    margin-top: 2rem;
  }
}

.content a:not(.button),
a.wavy-underline {
  -webkit-text-decoration: $green wavy underline;
  text-decoration: $green wavy underline;
  -webkit-text-decoration-thickness: 2px;
  text-decoration-thickness: 2px;

  // 2px in Firefox looks wonky
  @supports (-moz-appearance: none) {
    text-decoration-thickness: 1px;
  }
}

.box .author {
  color: $brown;

  .avatar {
    height: 48px;
    width: 48px;
    object-fit: cover;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
}

table.settings {
  tr > td:first-child {
    width: 65%;
  }
  &.bigger-output {
    tr > td:first-child {
      width: 40%;
    }
  }
  &.biggest-output {
    tr > td:first-child {
      width: 25%;
    }
  }
  .option,
  .filter {
    color: darken($blue, 10%);
  }
  .flag,
  .output {
    color: darken($orange, 20%);
  }
  p.default {
    margin-top: -0.5em;
    font-weight: bold;
    code {
      font-size: 1em;
      color: darken($green, 40%);
    }
  }

  th,
  td {
    &:first-child {
      background: lighten($white-ter, 2.5%);
    }
  }

  th {
    text-transform: uppercase;
  }

  @media screen and (max-width: 600px) {
    th {
      &:first-child:before {
        content: "👇 ";
      }
      &:last-child:after {
        content: " 👇";
      }
    }
    th,
    td {
      display: block;
      width: 100% !important;
      &:last-child {
        text-align: right !important;
      }
    }
  }
}

h2.no_toc {
  color: $orange;
}
ul#markdown-toc {
  border-left: solid 5px $orange;
  margin-left: -2rem;
  margin-bottom: 4.25rem !important;

  list-style-type: none;
  padding-left: calc(2rem - 5px);

  ul {
    margin-top: 0.5em !important;
    margin-bottom: 1em !important;
  }
  li + li {
    margin-top: 0.2em;
  }
  a {
    text-decoration: none;
    font-weight: 500;
  }
}
