@import "~fork-awesome";
@import "../fonts/inter.css";

$grey-darker: #2a2a26;
$grey-dark: #3e3f3a;
$grey: #8e8c84;
$grey-light: #c9c8b8;
$grey-lighter: #e6e1d7;

$white-ter: #f8f5f0;

$brown: #722e19;
.has-text-brown {
  color: $brown !important;
}
$orange: #f47c3c;
$green: #7bdda4;
$blue: #29abe0;
$red: #d9534f;

$primary: #0f5348 !default;
$info: $blue;
$warning: $orange;
$warning-invert: #fff;

$scheme-main: $white-ter;

$family-sans-serif: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
$family-monospace: ui-monospace, monospace;
$body-size: 18px;

$link: $primary;

$pagination-background-color: $grey-lighter;

$navbar-background-color: $primary;
$navbar-item-color: $white-ter;
$navbar-item-hover-color: white;
$navbar-item-active-color: white;
$navbar-item-active-background-color: darken($primary, 5%);
$navbar-item-hover-background-color: $navbar-item-active-background-color;
$navbar-dropdown-arrow: $navbar-item-color;

$card-shadow: 0 2px 3px rgba($grey-dark, 0.1), 0 0 0 1px rgba($grey-dark, 0.1);

$box-background-color: white;

$footer-background-color: $primary;
$footer-color: white;
footer.footer strong {
  color: white;
}

@import "custom_elements.css";

@import "~bulma/bulma";
@import "helpers.scss";
@import "content.scss";
@import "controls.scss";
@import "layout.scss";
@import "syntax.scss";
@import "typography.scss";
@import "components.scss"; // in src/_components
